import { useRouteLoaderData } from "@remix-run/react";
import type { RootLoaderData } from "./root";

export type RootLoaderType = RootLoaderData;

/**
 * NOTE: あくまでもサーバー側で再読み込みが起きたときのみ値が更新されるので、クライアント側だけで更新されることがあるものは流さないこと
 *
 * FIXME: テーマ周りとかはクライアント側だけで変更されることがあるので、流すのを別のところからにしてほしい
 */
export const useRootLoaderData = (): RootLoaderType => {
  const dat = useRouteLoaderData<RootLoaderType>("root");
  return dat as RootLoaderType;
};
